import { correspondenceOfNavAndPath } from './_ceh-header--current-highlight.js';

const GheaderDrowdown = class {
  #gheader
  #navMain;
  #seriesNavItem;
  #classnameActive;
  #offsetTimeBeforeSlide;
  #keywordSearch;
  #attrDropdownTrigger;
  #selectorDropdownTrigger;
  #breakpoint;
  #drawerTrigger;
  #drawerTarget;
  #keywordSearchDropdown;
  #keywordSearchInput;
  #triggerLanguageSwitchOpposite;
  #nameAttrAriaExpanded;
  #nameAttrAriaControls;
  #offsetBottomDropdownForMainNav;
  #classnameCurrent;

  constructor(gheader) {
    this.gheader = gheader;
    this.navMain = gheader.querySelector('.gheader__main-nav');
    this.classnameVisible = 'visible';
    this.classnameActive = 'active';
    this.offsetTimeBeforeSlide = 50;
    this.keywordSearch = gheader.querySelector('.gheader__sub-nav__keyword');
    this.attrDropdownTrigger = 'data-js-dropdown-trigger';
    this.selectorDropdownTrigger = '[' + this.attrDropdownTrigger + ']';
    this.seriesNavItemAll = Array.from(this.gheader.querySelectorAll(this.selectorDropdownTrigger));
    this.breakpoint = 1254;
    this.nameAttrScreenWidthPrev = 'screenWidthPrev';
    this.drawerTrigger = gheader.querySelector('.gheader__trigger_drawer');
    this.drawerTarget = gheader.querySelector('.gheader__container_drawer');
    this.keywordSearchContainer = gheader.querySelector('.gheader__sub-nav__keyword__content');
    this.keywordSearchInput = gheader.querySelector('.gheader__sub-nav__keyword__content__inputform__text');
    this.triggerLanguageSwitchOpposite = gheader.querySelectorAll('.js-languageSwitch');
    this.nameAttrAriaExpanded = 'aria-expanded';
    this.nameAttrAriaControls = 'aria-controls';
    this.offsetBottomDropdownForMainNav = 24;
    this.classnameCurrent = 'current';
  }

  init() {
    this.initNav(this.navMain, this.offsetBottomDropdownForMainNav);
    this.initNav(this.keywordSearch);
    this.initDrawerTrigger(this.drawerTrigger);
    this.resetDrawerOnResize();
    this.closeDropdownOnResize();
    this.switchLanguage(this.triggerLanguageSwitchOpposite);
    this.highlightNavCurrent();
  }

  // +++++++++++++++++++++++ Interaction
  // ハンバーガー開閉
  initDrawerTrigger(drawerTrigger) {
    // Sub Routines
    // 閉じる
    const _movementClose = () => {
      this.unlockScreen();

      this.deactivateAriaExpanded(drawerTrigger);
      drawerTrigger.innerText = drawerTrigger.dataset['labelToOpen'];

      this.slideUp(this.drawerTarget);
      this.drawerTrigger.classList.remove(this.classnameActive);
    };

    // 開く
    const _movementOpen = () => {
      drawerTrigger.classList.add(this.classnameActive);
      this.activateAriaExpanded(drawerTrigger);
      drawerTrigger.innerText = drawerTrigger.dataset['labelToClose'];

      this.slideDown(this.drawerTarget, 0, true);
      this.lockScreen();
    };

    // outerclick
    const _movementOuterclick = (ev) => {
      if(this.closestByDom(ev.target, this.drawerTarget)) { return; }
      if(ev.target === drawerTrigger) { return; }

      _movementClose();
    };

    // Main Routine
    // 閉じる
    drawerTrigger.addEventListener('click', () => {
      if(this.drawerTarget.classList.contains(this.classnameVisible)) {
        _movementClose();
        return;
      }
      // 開く
      _movementOpen();
    });

    // outerclick
    document.body.addEventListener('click', (ev) => {
      _movementOuterclick(ev);
    });
  }

  initNav(elemRange, offsetBottom) {
    const rangedSeriesNavItem = Array.from(elemRange.querySelectorAll(this.selectorDropdownTrigger));
    rangedSeriesNavItem.forEach((navItem) => {
      this.bindToggleDropdown(navItem, elemRange, offsetBottom);
      this.bindMovementCloseButton(navItem);
    });

    this.bindResetActiveAll(elemRange);
  }

  bindToggleDropdown(navItem, elemRange, offsetBottom) {
    navItem.addEventListener('click', (ev) => {

      ev.preventDefault();

      const dropdownContainer = navItem.nextElementSibling;

      if(navItem.classList.contains(this.classnameActive)) {
        this.removeActiveSign(navItem);
        this.slideUp(dropdownContainer);

        this.deactivateAriaExpanded(navItem);

        if(this.gheader.querySelector('.' + this.classnameActive)) {
          return;
        }

        this.unlockScreen();
        return;
      }

      // ドロップダウン開閉挙動 PC・SP 分岐
      // PC
      if(window.innerWidth > this.breakpoint) {
        // クロスフェード
        if(Array.from(elemRange.querySelectorAll('.' + this.classnameActive + this.selectorDropdownTrigger)).length > 0) {
          const rangedSeriesDropdown = Array.from(elemRange.querySelectorAll('.gheader__main-nav-dropdown'));
          this.removeActiveSignAll(this.seriesNavItemAll);
          this.addActiveSign(navItem);

          this.fadeOutAll(rangedSeriesDropdown);
          this.slideDown(dropdownContainer, offsetBottom); // height値付与
          this.fadeIn(dropdownContainer);
          this.activateAriaExpanded(navItem);
          return;
        }
        // トグル
        else {
          this.removeActiveSignAll(this.seriesNavItemAll);
          this.slideUpAll(this.seriesNavItemAll);

          this.addActiveSign(navItem);
          this.activateAriaExpanded(navItem);
          this.lockScreen();
          this.slideDown(dropdownContainer, offsetBottom);
        }
      }
      // SP
      else {
        this.addActiveSign(navItem);
        this.activateAriaExpanded(navItem);
        this.lockScreen();
        this.slideDown(dropdownContainer);
      }

    });
  }

  bindMovementCloseButton(navItem) {
    const dropdownContainer = navItem.nextElementSibling;
    const closeButton = dropdownContainer.querySelector('.gheader__main-nav-dropdown-bt_close');
    if(!closeButton) { return; }

    closeButton.addEventListener('click', () => {
      this.removeActiveSign(navItem);
      this.slideUp(dropdownContainer);

      const triggerInNavParent = this.gheader.querySelector('[aria-controls="__id__"]'.replace('__id__', dropdownContainer.getAttribute('id')));
      this.deactivateAriaExpanded(triggerInNavParent);

      this.unlockScreen();
    })
  }

  fadeIn(elemFade) {
    elemFade.removeEventListener('transitionend',  elemFade.movementAfterFade);
    elemFade.removeEventListener('transitionend', elemFade.movementAfterSlideUp);
    elemFade.classList.add('visible');
    elemFade.classList.add('cancelAnimation');
    elemFade.classList.add('transparent');
    elemFade.classList.add('active');
    elemFade.classList.remove('cancelAnimation');
    setTimeout(() => {
      elemFade.classList.remove('transparent');
    });
  }
  fadeOut(elemFade) {
    if(!elemFade.movementAfterFade) {
      elemFade.movementAfterFade = () => {
        elemFade.classList.add('cancelAnimation');
        elemFade.style.removeProperty('height');
        elemFade.classList.remove('visible');
        elemFade.classList.remove('transparent');
        elemFade.classList.remove('cancelAnimation');
        elemFade.removeEventListener('transitionend',  elemFade.movementAfterFade);
      }
    }

    elemFade.removeEventListener('transitionend', elemFade.movementAfterSlideUp);
    elemFade.addEventListener('transitionend',  elemFade.movementAfterFade);
    elemFade.classList.add('transparent');
  }

  fadeOutAll(seriesElemFade) {
    seriesElemFade.forEach((elemFade) => {
      this.fadeOut(elemFade)
    })
  }

  slideDown(elemSlide, offsetBottom = 0, fillScreen = false) {
    const cloned = elemSlide.cloneNode(true);
    const container = elemSlide.parentNode;
    let heightDest;

    const prepare = () => {
      cloned.classList.add(this.classnameVisible);
      cloned.style.visibility = 'hidden';
      cloned.classList.add('cancelAnimation');
      cloned.classList.add('testHeight');
      cloned.style.position = 'absolute';
      container.prepend(cloned);

      elemSlide.classList.remove('transparent');

      elemSlide.removeEventListener('transitionend', elemSlide.movementAfterSlideUp);
      elemSlide.removeEventListener('transitionend',  elemSlide.movementAfterFade);
      elemSlide.removeEventListener('transitionend', elemSlide.movementAfterSlideDown);

      elemSlide.setAttribute('data-sliding', '');

      // 開閉中であることを data-sliding として保持
      if(!elemSlide.movementAfterSlideDown) {
        elemSlide.movementAfterSlideDown = () => {
          elemSlide.removeAttribute('data-sliding');
          elemSlide.removeEventListener('transitionend', elemSlide.movementAfterSlideDown);
        }
      }
      elemSlide.addEventListener('transitionend', elemSlide.movementAfterSlideDown);
    }

    const measureHeight = () => {
      return new Promise((resolve) => {

        setTimeout(() => {
          heightDest = (() => {
            const heightHeader = this.gheader.clientHeight - this.keywordSearchContainer.clientHeight;
            const heightFillScreen = window.innerHeight - heightHeader;

            let ret  = Number(window.getComputedStyle(cloned)['height'].replace('px', ''));
            if(ret > heightFillScreen) { ret = ret - offsetBottom };
            if(fillScreen) { ret = heightFillScreen; }
            return ret + 'px';
          })();

          cloned.remove();
          resolve();
        });
      });
    }

    const applyHeight = () => {
      return new Promise((resolve) => {
        elemSlide.classList.add(this.classnameVisible);
        setTimeout(() => {
          elemSlide.style.height = heightDest;
          resolve();
        }, this.offsetTimeBeforeSlide);
      });
    }

    (async () => {
      prepare();
      await measureHeight();
      await applyHeight();
    })();
  }

  slideUp(elemSlide, immediate) {
    if(!elemSlide.movementAfterSlideUp) {
      elemSlide.movementAfterSlideUp = () => {
        elemSlide.classList.remove(this.classnameVisible);
        elemSlide.classList.remove('transparent');
        elemSlide.removeAttribute('data-sliding');
        elemSlide.addEventListener('transitionend', elemSlide.movementAfterFade);
        elemSlide.removeEventListener('transitionend', elemSlide.movementAfterSlideDown);
        elemSlide.removeEventListener('transitionend', elemSlide.movementAfterSlideUp);
      }
    }

    // 開閉中であることを data-sliding として保持
    elemSlide.setAttribute('data-sliding', '');

    elemSlide.classList.remove(this.classnameActive);
    elemSlide.addEventListener('transitionend', elemSlide.movementAfterFade);
    elemSlide.removeEventListener('transitionend', elemSlide.movementAfterSlideDown);
    elemSlide.addEventListener('transitionend', elemSlide.movementAfterSlideUp);
    elemSlide.style.removeProperty('height');

    if(immediate) {
      elemSlide.removeAttribute('data-sliding', '');
    }
  }

  resetDrawerOnResize() {
    window.addEventListener('resize', () => {
      const screenWidthPrev = (() => {
        const ret = this.gheader.dataset[this.nameAttrScreenWidthPrev];
        if(!ret) { return 0; }
        return Number(this.gheader.dataset[this.nameAttrScreenWidthPrev]);
      })();
      const screenWidthCurrent = window.innerWidth;

      if((screenWidthCurrent >= this.breakpoint) && (screenWidthPrev < this.breakpoint)) {
        this.deactivateAriaExpanded(this.drawerTrigger);
        this.drawerTarget.classList.remove(this.classnameVisible);
        this.drawerTrigger.innerText = this.drawerTrigger.dataset['labelToOpen'];
      }
    });
  }

  closeDropdownOnResize() {
    window.addEventListener('resize', () => {
      const screenWidthPrev = (() => {
        const ret = this.gheader.dataset[this.nameAttrScreenWidthPrev];
        if(!ret) { return 0; }
        return Number(this.gheader.dataset[this.nameAttrScreenWidthPrev]);
      })();
      const screenWidthCurrent = window.innerWidth;

      if((screenWidthCurrent >= this.breakpoint) && (screenWidthPrev < this.breakpoint)) {
        this.removeActiveSignAll(this.seriesNavItemAll);
        this.slideUpAll(this.seriesNavItemAll);

        this.drawerTrigger.classList.remove(this.classnameActive);
        this.slideUp(this.drawerTarget);
        this.unlockScreen();
      }

      this.gheader.dataset[this.nameAttrScreenWidthPrev] = screenWidthCurrent.toString();
    });
  }

  switchLanguage (triggerLanguageSwitchOpposite) {
    triggerLanguageSwitchOpposite.forEach((target) => {
      target.setAttribute('href', `/bin/switchlanguage?path=` + getPath());
    });

    function getPath() {
      const path = window.location.pathname;

      if (path.search(/\/ja\//) !== -1) {
        return path.replace('/ja/', '/en/');
      } else if (path.search(/\/en\//) !== -1) {
        return path.replace('/en/', '/ja/');
      }
    }
  }

  highlightNavCurrent() {
    const gheader = this.gheader;
    const nameAttrNavCategory = 'data-gheader-nav-category';
    const keyNavCategory = 'navCategory';
    const seriesNavToBeHighlighten = Array.from(gheader.querySelectorAll('[' + nameAttrNavCategory + ']'));
    const delimPath = '/';

    const classnameCurrent = this.classnameCurrent;

    const pathCurrent = (() => {
      return location.pathname;
    })()

    const highlight = (path) => {
      seriesNavToBeHighlighten.forEach((navToBeHighlighten) => {
        const navCategory = navToBeHighlighten.getAttribute(nameAttrNavCategory);

        if(navCategory === path) {
          navToBeHighlighten.classList.add(classnameCurrent);
          return;
        }

        navToBeHighlighten.classList.remove(classnameCurrent);
      });
    };

    const getDirname = (path) => { return path.replace(/\/[^\/]*?$/g, delimPath); };

    const searchPrefixmatch = (path, correspondence) => {
      let pathProcessed = getDirname(path);
      const arrayPath = path.split(delimPath);

      if(correspondence[pathProcessed]) {
        return correspondence[pathProcessed];
      }

      while(!correspondence[pathProcessed] && arrayPath.length) {
        arrayPath.pop();
        pathProcessed = arrayPath.join(delimPath) + delimPath;
      }

      if(!arrayPath.length) { return ''; }
      return correspondence[pathProcessed];
    }

    // 対応表を探索
    const correspondence = correspondenceOfNavAndPath();

    // フルパス（https://〜）
    const correspondenceWithFullPath = searchPrefixmatch(location.href, correspondence);
    if(correspondenceWithFullPath) {
      console.log(correspondenceWithFullPath);
      console.log('FullPath: [' + correspondenceWithFullPath + ']');
      highlight(correspondenceWithFullPath[keyNavCategory]);
      return;
    }


    // ルートパス（ファイル名含む）
    const correspondenceWithRootPath = correspondence[pathCurrent];
    if(correspondenceWithRootPath) {
      // console.log('RootPath: [' + correspondenceWithRootPath[keyNavCategory] + ']');
      highlight(correspondenceWithRootPath[keyNavCategory]);
      return;
    }

    // ルートパス前方一致
    const correspondenceWithPrefixMatch = searchPrefixmatch(pathCurrent, correspondence);
    if(correspondenceWithPrefixMatch) {
      // console.log('PrefixMatch: [' + correspondenceWithPrefixMatch[keyNavCategory] + ']');
      highlight(correspondenceWithPrefixMatch[keyNavCategory]);
      return;
    }

    // ディレクトリ
    const correspondenceWithDir = correspondence[getDirname(pathCurrent)];
    if(correspondenceWithDir) {
      // console.log('Dir: [' + correspondenceWithDir[keyNavCategory] + ']');
      highlight(correspondenceWithDir[keyNavCategory]);
      return;
    }

    // console.log('No Correspondence in navigation');
  }

  // +++++++++++++++++++++++ Util
  lockScreen(resetCurrentPosition, options) {
    const settingsSrc = {
      pageScreen: document.body,
      scrolledBefore: window.scrollY,
      nameAttrScrollDistance: 'data-scrolled-on-last-locked',
      selectorFixed: '.g-header, .gheader__main-nav-dropdown visible',
      classnameLocked: 'lockScroll',
    };
    const settings = Object.assign(settingsSrc, options);

    const pageScreen = document.body;

    const widthScreenBefore = settings.pageScreen.clientWidth;
    if(settings.pageScreen.classList.contains(settings.classnameLocked)) { return; }

    settings.pageScreen.classList.add(settings.classnameLocked); 

    if(resetCurrentPosition !== true) {
      settings.pageScreen.setAttribute(settings.nameAttrScrollDistance, settings.scrolledBefore.toString());
      settings.pageScreen.style.top = (0 - settings.scrolledBefore) + 'px';
    }

    const widthScreenAfter = settings.pageScreen.clientWidth;
    const diffBeforeAfter = widthScreenAfter - widthScreenBefore;

    settings.pageScreen.style.paddingRight = diffBeforeAfter + 'px';
    Array.from(document.querySelectorAll(settings.selectorFixed)).forEach((elemTarget) => {
      elemTarget.style.paddingRight = diffBeforeAfter + 'px';
    });

    // 当処理(lockScreen()) で惹起された不用意な scroll イベントを他処理でバイパスできるようにする
    const nameAttrUnlocked = 'data-locked';
    const valueAttrUnlocked = 'locked';
    pageScreen.setAttribute(nameAttrUnlocked, valueAttrUnlocked);
    setTimeout(() => {pageScreen.removeAttribute(nameAttrUnlocked)}, 200);

    return diffBeforeAfter;
  };

  unlockScreen(options) {
    const settingsSrc = {
      selectorFixed: '.gheader, .gheader__main-nav-dropdown visible'
    };
    const settings = Object.assign(settingsSrc, options);

    const pageScreen = document.body;
    const nameAttrScrollDistance = 'data-scrolled-on-last-locked';
    const classnameLocked = 'lockScroll';
    const seriesStyleAttribute = ['top', 'padding-right', 'margin-right', 'width'];

    Array.from(document.querySelectorAll(settings.selectorFixed)).forEach((elemTarget) => {
      this.removeOverriddenStyle(elemTarget, seriesStyleAttribute);
    });
    this.removeOverriddenStyle(pageScreen, seriesStyleAttribute);

    pageScreen.classList.remove(classnameLocked);

    if(pageScreen.hasAttribute(nameAttrScrollDistance)) {
      window.scrollTo(0, Number(pageScreen.getAttribute(nameAttrScrollDistance)));
    }

    pageScreen.removeAttribute(nameAttrScrollDistance);

    // 当処理(unlockScreen()) で惹起された不用意な scroll イベントを他処理でバイパスできるようにする
    const nameAttrUnlocked = 'data-unlocked';
    const valueAttrUnlocked = 'unlocked';
    pageScreen.setAttribute(nameAttrUnlocked, valueAttrUnlocked);
    setTimeout(() => { pageScreen.removeAttribute(nameAttrUnlocked) }, 200);
  };

  // DOMのstyle属性から特定のプロパティ分を撤去
  removeOverriddenStyle(elemTarg, seriesPropCss) {
    seriesPropCss.forEach((propCss) => {
      elemTarg.style.removeProperty(propCss);
    })
    return elemTarg;
  };

  addActiveSign(navItem) {
    navItem.classList.add(this.classnameActive);
  }
  removeActiveSign(navItem) {
    navItem.classList.remove(this.classnameActive);
  }
  removeActiveSignAll(seriesNavItem) {
    seriesNavItem.forEach((navItem) => {
      this.removeActiveSign(navItem);
    });
  }

  activateAriaExpanded(target) {
    target.setAttribute(this.nameAttrAriaExpanded, 'true');
  }
  deactivateAriaExpanded(target) {
    target.setAttribute(this.nameAttrAriaExpanded, 'false');
  }

  slideUpAll(seriesNavItem) {
    seriesNavItem.forEach((navItem) => {
      const elemDropdown = navItem.nextElementSibling;
      this.slideUp(elemDropdown, true);
    });
  }

  bindResetActiveAll(elemRange) {
    const seriesNavItem = elemRange.querySelectorAll(this.selectorDropdownTrigger);
    document.body.addEventListener('click', (ev) => {
      if(this.closestByDom(ev.target, elemRange) || ev.target.hasAttribute(this.attrDropdownTrigger)) {
        return;
      }

      this.removeActiveSignAll(seriesNavItem);
      this.slideUpAll(seriesNavItem);

      // A11Y aria-expanded
      elemRange.querySelectorAll('[__nameAttr__="true"]'.replace('__nameAttr__', this.nameAttrAriaExpanded))
        .forEach((triggerInNavParent) => {
          this.deactivateAriaExpanded(triggerInNavParent);
        })
      ;

      if(this.gheader.querySelector('.' + this.classnameActive)) {
        return;
      }

      this.unlockScreen();
    });
  }

  closestByDom(elemStart, elemDest) {
    if(elemStart === elemDest) { return true; }
    if(elemDest.contains(elemStart)) { return true; }
    return false;
  }
};

const DrawerHeader = class {
  settingsSrc = {
    headerContainerSelector: 'header.experiencefragment:has(.gheader)',
    classnameStored: 'stored',
    nameAttrBypassingDrawer: 'data-bypass-drawer',
    selectorKeywordSearchDropdown: '.gheader__sub-nav__keyword__content'
  };

  scrolledDistanceBefore;
  scrolledDistanceCurrent;
  globalHeader;
  headerContainerSelector;
  nameAttrTransparent;
  classnameStored;
  screenTopOffset;
  nameAttrBypassingDrawer;
  keywordSearchDropdown;

  watchTarget;

  constructor(watchTargetOverride, options) {
    if(!watchTargetOverride) { this.watchTarget = window; }
    else { this.watchTarget = watchTargetOverride; }
    if(!this.watchTarget) { return; }

    this.settingsSrc = Object.assign(this.settingsSrc, options);
    Object.keys(this.settingsSrc).forEach((key) => {
      if(typeof(options) === 'undefined' || typeof(options[key]) === 'undefined') {
        this[key] = this.settingsSrc[key];
        return;
      }

      this[key] = options[key];
    });

    this.scrolledDistanceBefore = 0;
    this.scrolledDistanceCurrent = this.getScrolledDistance();
    this.globalHeader = document.querySelector(this.headerContainerSelector);
    this.keywordSearchDropdown = document.querySelector(this.selectorKeywordSearchDropdown);

    this.init();
  }

  init() {
    this.watchScroll();
  }

  watchScroll() {
    this.watchTarget.addEventListener('scroll', () => { this.drawerHandler() });
  }

  getScrolledDistance() {
    if(this.watchTarget === window) { return this.watchTarget.scrollY; }
    else { return this.watchTarget.scrollTop; }
  }

  getScreenTopOffset() {
    return this.globalHeader.clientHeight;
  }

  detectScrolledDirection() {
    this.scrolledDistanceCurrent = this.getScrolledDistance();
    let scrolledDirection = 'stay';

    if(this.scrolledDistanceCurrent > this.scrolledDistanceBefore) {
      scrolledDirection = 'down';
    }
    else if(this.scrolledDistanceCurrent < this.scrolledDistanceBefore) {
      scrolledDirection = 'up';
    }

    this.scrolledDistanceBefore = this.scrolledDistanceCurrent;
    return scrolledDirection;
  }

  isBypassingDrawer = () => {
    return(this.globalHeader.hasAttribute(this.nameAttrBypassingDrawer));
  }

  drawerHandler() {
    if(document.body.hasAttribute('data-locked') || document.body.hasAttribute('data-unlocked')) { return; }
    if(this.isBypassingDrawer()) { return; }
    if(this.keywordSearchDropdown.hasAttribute('data-sliding')) { return; }

    if(this.getScrolledDistance() <= this.getScreenTopOffset()) {
      this.globalHeader.classList.remove(this.classnameStored);

      this.globalHeader.setAttribute(this.nameAttrTransparent, 'true');
      return;
    }

    const scrolledDirection = this.detectScrolledDirection();
    this.toggleClassScrolled();

    if(scrolledDirection === 'up') {
      this.globalHeader.classList.remove(this.classnameStored);
    }
    else {
      this.globalHeader.classList.add(this.classnameStored);
    }
  }

  toggleClassScrolled() {

    if(this.getScrolledDistance() <= this.getScreenTopOffset()) {
      this.globalHeader.setAttribute(this.nameAttrTransparent, 'true');
    }
    else {
      this.globalHeader.setAttribute(this.nameAttrTransparent, 'false');
    }
  }
}

$(function() {
  new GheaderDrowdown(document.querySelector('.gheader')).init();
  new DrawerHeader(window, {});
});
